<template>
  <div
    v-if="
      $feature.isEnabled('help_above_input')
        ? !context.slotProps.help.helpBelowInput
        : false
    "
  >
    <label :id="context.id" :for="context.id" :class="context.classes.label">
      {{ context.label }}
      <HelpLink
        v-if="context.slotProps.label.helpLink"
        :url="context.slotProps.label.helpLink"
        :tooltip="tooltipConfig"
      />
      <div
        v-else-if="context.slotProps.label.helpTooltip"
        v-tooltip="context.slotProps.label.helpTooltip"
        :class="$style.help"
        :data-i="context.slotProps.label.helpTooltipIcon || 'help_outlined'"
      ></div>
      <Badge
        v-if="context.slotProps.label.badge"
        v-bind="context.slotProps.label.badge"
        pill
        :class="$style.badge"
      />
    </label>
    <FormulateHelp :context="context" />
  </div>
  <label
    v-else
    :id="context.id"
    :for="context.id"
    :class="context.classes.label"
  >
    {{ context.label }}
    <Badge
      v-if="context.slotProps.label.badge"
      v-bind="context.slotProps.label.badge"
      pill
      :class="$style.badge"
    />
    <HelpLink
      v-if="context.slotProps.label.helpLink"
      :url="context.slotProps.label.helpLink"
      :tooltip="tooltipConfig"
    />
    <div
      v-else-if="context.slotProps.label.helpTooltip"
      v-tooltip="context.slotProps.label.helpTooltip"
      :class="$style.help"
      :data-i="context.slotProps.label.helpTooltipIcon || 'help_outlined'"
    ></div>
  </label>
</template>

<script>
import { toTooltipConfig } from '../../helpers/tooltip-helpers';
import HelpLink from './HelpLink.vue';

export default {
  components: {
    HelpLink,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  computed: {
    tooltipConfig() {
      return {
        placement: 'right',
        ...toTooltipConfig(this.context.slotProps.label.helpLinkTooltip),
      };
    },
  },
};
</script>

<style module>
.help {
  &::before {
    width: 1em;
    cursor: help;
    color: var(--gray-7);
  }
}

.badge {
  margin-left: 0.5em;
  font-weight: normal;
  font-size: 0.875rem;
  margin-top: -0.25em;
}
</style>
