import { ref } from 'vue';
import { SessionType, type User } from '@cutr/constants/user';
import api from '@/helpers/api';
import { CustomerOrganisation, Organization } from '@cutr/constants/order';

const user = ref<User | null>(null);
const userOrganisation = ref<Organization | CustomerOrganisation | null>(null);

export function useUser() {
  const isLoading = ref(false);

  const fetchUser = async (id: string) => {
    isLoading.value = true;
    try {
      const userResponse = await api.get(`/v2/users/${id}`);
      user.value = userResponse.data.data;
    } catch (error) {
      console.error('Error fetching user:', error);
    } finally {
      isLoading.value = false;
    }
  };

  const fetchUserOrganisation = async (
    id: string,
    sessionType: SessionType
  ) => {
    isLoading.value = true;
    try {
      const organisationResponse = await api.get(
        `/v2/users/${id}/${
          sessionType === 'Customer' ? 'customer-organisation' : 'organisation'
        }`
      );
      if (sessionType === 'Customer') {
        userOrganisation.value = organisationResponse.data
          .data as CustomerOrganisation;
      }
      if (sessionType === 'Maker') {
        userOrganisation.value = organisationResponse.data.data as Organization;
      }
    } catch (error) {
      console.error('Error fetching user organisation:', error);
    } finally {
      isLoading.value = false;
    }
  };

  const clearUser = () => {
    user.value = null;
    userOrganisation.value = null;
  };

  return {
    user,
    isLoading,
    userOrganisation,
    clearUser,
    fetchUser,
    fetchUserOrganisation,
  };
}
