<!-- eslint-disable vue/no-bare-strings-in-template -->
<template>
  <div v-if="isVisible" class="banner">
    <div class="banner-content">
      <p>
        <span class="material-icons-outlined">info</span>
        <span v-if="!hasError">{{
          activeUserEmailConfirmation
            ? $t('common:emailConfirmation.headerSent', {
                email: activeUserEmailConfirmation.email,
              })
            : $t('common:emailConfirmation.header')
        }}</span>
        <span v-if="hasError" class="error-message">
          {{ $t('common:emailConfirmation.sendError') }}
        </span>
      </p>
      <div class="banner-actions">
        <Button
          v-if="!hasError"
          class="nowrap"
          icon="email"
          :disabled="isLoading"
          @click="sendVerificationEmail"
        >
          {{
            activeUserEmailConfirmation
              ? $t('common:emailConfirmation.resendButton')
              : $t('common:emailConfirmation.confirmationButton')
          }}
        </Button>
        <Button icon="close" @click="closeBanner">{{
          $t('common:emailConfirmation.closeButton')
        }}</Button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import Button from '../common/Button.vue';
import { useApi, useToast, useTranslation } from '../../composables/plugins';
import { useActiveUserStore } from '@/modules/store';
import { computed } from 'vue';
import { useUser } from '@/composables/users';
import { onMounted } from 'vue';

const api = useApi();
const toast = useToast();
const { t } = useTranslation();
const isVisible = ref(false);
const hasError = ref(false);
const isLoading = ref(false);

const { activeUser } = useActiveUserStore();

const { user, fetchUser } = useUser();

onMounted(async () => {
  if (sessionStorage.getItem('hideEmailConfirmationBanner')) {
    return;
  }

  if (activeUser && activeUser.emailStatus === 'unconfirmed') {
    isVisible.value = true;
    await fetchUser(activeUser.id);
  }
});

const activeUserEmailConfirmation = computed(() =>
  user.value?.emailConfirmations?.find(
    (emailConfirmation) => emailConfirmation.resourceField === 'email'
  )
);

const sendVerificationEmail = async () => {
  hasError.value = false;
  isLoading.value = true;

  try {
    await api.post(
      activeUserEmailConfirmation.value
        ? `/v2/email-confirmations/${activeUserEmailConfirmation.value.id}/resend`
        : `/v2/users/${activeUser.id}/request-email-confirmation`
    );
    isVisible.value = false;
    toast.success(t('common:emailConfirmation.confirmationSent'));
    await fetchUser(activeUser.id);
  } catch {
    hasError.value = true;
  } finally {
    isLoading.value = false;
    sessionStorage.setItem('hideEmailConfirmationBanner', 'true');
  }
};

function closeBanner() {
  isVisible.value = false;
  sessionStorage.setItem('hideEmailConfirmationBanner', 'true');
}
</script>

<style scoped>
.banner {
  background-color: var(--gold-2);
  padding: 1rem;
  width: 100%;
  position: fixed;
  top: var(--header-height, 3.75rem);
  z-index: 100;
}

.banner-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 75rem;
  margin: 0 auto;
  gap: 1rem;
}

.banner-actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.nowrap {
  white-space: nowrap;
}

p {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.material-icons-outlined {
  font-size: 1.25rem;
}

.error-message {
  color: var(--error);
}
</style>
