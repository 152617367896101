<template>
  <div id="app" :class="{ 'grey-background': !this.$route.meta.noBackground }">
    <!-- eslint-disable-next-line vue/no-bare-strings-in-template -- admin only -->
    <div v-if="isSuperUser" class="red-border">Signed in as super user</div>
    <UnconfirmedEmailBanner v-if="showEmailUnconfirmedBanner" />
    <Navigation v-if="showChrome" />
    <main
      id="main-view"
      :class="{ offsetnav: showChrome, loading: !$i18next.isInitialized }"
      v-if="!isMaintenanceModeEnabled"
      :data-route-name="currentRoute.name"
    >
      <router-view v-if="$i18next.isInitialized"></router-view>
      <!-- eslint-disable-next-line vue/no-bare-strings-in-template -- this is before i18next is initialized -->
      <div class="loading" v-else>Loading <LoadingDots /></div>
    </main>
    <CutrFooter v-if="showChrome" />

    <ModalGlobal />
    <ModalConfirm />
    <MaintenanceScreen v-if="isMaintenanceModeEnabled" />
  </div>
</template>

<script>
import Navigation from '../components/pages/Navigation.vue';
import CutrFooter from '../components/pages/Footer.vue';
import ModalConfirm from '../components/elements/ModalConfirm.vue';
import ModalGlobal from '../components/elements/ModalGlobal.vue';
import MaintenanceScreen from '../components/elements/MaintenanceScreen.vue';
import UnconfirmedEmailBanner from '../components/elements/UnconfirmedEmailBanner.vue';

import '../scss/cutr.scss';
import { useActiveUserStore } from '@/modules/store';
import { mapState } from 'pinia';

export default {
  name: 'app',

  data() {
    return {
      bypassMaintenanceMode: false,
      intercomCheckAttempts: 0,
      hasIntercom: false,
    };
  },

  mounted: function () {
    const getCookie = (name) =>
      document.cookie
        .split('; ')
        .find((row) => row.startsWith(name))
        ?.split('=')[1];

    const eraseCookie = (name) => {
      var pastDate = new Date(0);
      document.cookie =
        name +
        '=; expires=' +
        pastDate.toUTCString() +
        '; path=/; domain=.cutr.com';
    };

    const rawCookie = getCookie('tracking-preferences');
    if (!rawCookie) return;

    const decodedCookie = decodeURIComponent(rawCookie);
    let cookie;

    try {
      cookie = JSON.parse(decodedCookie);
    } catch (err) {
      console.error(err);
    }

    if (cookie?.integrations?.['All'] === false) {
      eraseCookie('tracking-preferences');
      this.$track('consent-manager:segment-bad-cookie', { cookie });
      this.$forceUpdate();
    }

    this.trackIntercom();
  },

  watch: {
    '$i18next.resolvedLanguage': {
      immediate: true,
      handler(lang) {
        this.$formulate.setLocale(lang);
      },
    },
  },

  components: {
    Navigation,
    ModalGlobal,
    ModalConfirm,
    MaintenanceScreen,
    CutrFooter,
    UnconfirmedEmailBanner,
  },

  computed: {
    currentRoute() {
      return this.$route;
    },

    showChrome() {
      return (
        !this.isMaintenanceModeEnabled &&
        !this.currentRoute.meta?.disableAppChrome &&
        this.$i18next.isInitialized
      );
    },

    isMaintenanceModeEnabled() {
      if (this.currentRoute.name === 'signin') return false;
      if (this.bypassMaintenanceMode) return false;
      return this.$feature.isMaintenanceModeEnabled();
    },
    showEmailUnconfirmedBanner() {
      return (
        this.activeUser.emailStatus === 'unconfirmed' &&
        this.isLoggedIn &&
        this.$feature.isEnabled('email-deliverability')
      );
    },
    ...mapState(useActiveUserStore, [
      'superUserId',
      'activeUser',
      'isLoggedIn',
    ]),
    isSuperUser() {
      return Boolean(this.superUserId);
    },
  },
  methods: {
    trackIntercom() {
      if (this.hasIntercom) {
        window.Intercom?.('onShow', () => {
          this.$track('intercom:shown', {
            page: this.$route.name,
          });
        });

        window.intercomSettings = {
          ...(window.intercomSettings || {}),
          vertical_padding: 104,
        };
        return;
      }

      if (this.intercomCheckAttempts > 10) return;

      setTimeout(() => {
        this.hasIntercom = Boolean(window.Intercom);
        this.intercomCheckAttempts = this.intercomCheckAttempts + 1;
        this.trackIntercom();
      }, this.intercomCheckAttempts * 1000);
    },

    closeBanner() {
      this.showBanner = false;
    },
  },
};
</script>
