<template>
  <Button
    icon="download"
    :icon-before="iconBefore"
    :loadingProgress="progress"
    :onClick="onClick"
    :variant="variant"
    :disabled="isDisabled"
    :tooltip="tooltip"
    :trackOverride="trackOverride"
    ><slot>{{ label }}</slot></Button
  >
</template>

<script>
const DOWNLOAD_TYPES = (t) => ({
  offer: {
    path: ({ id }) => `/v2/offers/${id}/downloads/pdf`,
    fileName: ({ order_id, label }) =>
      `${t('files:offerFileName', {
        orderId: order_id,
        label,
      })}.pdf`,
  },
  customerinvoice: {
    path: ({ id }) => `/v2/customerinvoices/${id}/download`,
    fileName: ({ order_id, label }) =>
      `${t('files:sprinqueInvoiceFileName', {
        orderId: order_id,
        label,
      })}.pdf`,
  },
  supplierinvoice: {
    path: ({ invoicePDFURL }) => invoicePDFURL,
    fileName: ({ label }) =>
      `${t('files:supplierInvoiceFileName', { label })}.pdf`,
    config: { withCredentials: false },
    isDisabled: ({ invoicePDFURL }) => !invoicePDFURL,
  },
  proformainvoice: {
    path: ({ proformaPDFURL }) => proformaPDFURL,
    fileName: ({ proformaLabel }) =>
      `${t('files:proformaInvoiceFileName', {
        label: proformaLabel,
      })}.pdf`,
    config: { withCredentials: false },
    isDisabled: ({ proformaPDFURL }) => !proformaPDFURL,
  },

  selfbillinginvoice: {
    path: ({ id }) => `/v2/selfbillinginvoices/${id}/downloads/pdf`,
    fileName: ({ shortOrderId, label }) =>
      `${t('files:selfBillingInvoiceFileName', {
        shortOrderId,
        label,
      })}.pdf`,
  },

  quote: {
    path: ({ id }) => `/v2/quotes/${id}/downloads/pdf`,
    fileName: ({ order_id, label }) =>
      `${t('files:quoteFileName', {
        orderId: order_id,
        label,
      })}`,
  },

  orderfile: {
    path: ({ id }) =>
      `/v2/order-files/${id}/download?renderAdditionalData=true`,
    fileName: ({ name, revisionNumber }, serverFileName) => {
      const [ext, ...baseNames] = (serverFileName || name).split('.').reverse();
      return `${baseNames.reverse().join('.')} - ${t('files:revisionNumber', {
        revisionNumber,
      })}.${ext}`;
    },
  },
  originalfile: {
    path: ({ id }) => `/v2/order-files/${id}/download`,
    fileName: ({ name, revisionNumber }, serverFileName) => {
      const [ext, ...baseNames] = (serverFileName || name).split('.').reverse();
      return `${baseNames.reverse().join('.')} - ${t('files:revisionNumber', {
        revisionNumber,
      })}.${ext}`;
    },
  },

  packingSlip: {
    path: ({ orderVariantId }) =>
      `/v2/order-variants/${orderVariantId}/downloads/packing-slip`,
    fileName: ({ shortId }) =>
      `${t('files:packingSlipFileName', {
        shortId,
      })}.pdf`,
  },

  orderSupportingFile: {
    path: ({ url }) => url,
    fileName: ({ name }) => name,
    config: { withCredentials: false },
  },

  orderAll: {
    path: ({ orderVariantId }) =>
      `/v2/order-variants/${orderVariantId}/downloads/all`,
    fileName: ({ shortId }) => `Cutr ${shortId}.zip`,
  },
  orderAllAsync: {
    override: true,
    path: ({ orderVariantId }) =>
      `/v2/order-variants/${orderVariantId}/downloads/all-async`,
  },

  orderNestingDXFs: {
    path: ({ orderVariantId }) =>
      `/v2/order-variants/${orderVariantId}/downloads/nesting-dxfs`,
    fileName: ({ shortId }) => `${t('files:nestingFileName', { shortId })}.zip`,
  },
  cutlistAdmin: {
    path: ({ id }) => `/v2/cutlist/${id}/downloads/zip`,
    fileName: ({ id }) => `cutlist ${id}.zip`,
  },
  cutlistEmbedded: {
    path: ({ orderVariantId }) =>
      `/v2/order-variants/${orderVariantId}/embedded-cutlist-files/zip`,
    fileName: ({ orderVariantId }) => `cutlist ${orderVariantId}.zip`,
  },
  exampleFile: {
    path: () => 'https://docs.cutr.ai/docs/Cutr-Demo-Corpus.step',
    fileName: () => 'Cutr - Demo Corpus.step',
    config: { withCredentials: false },
  },
});

export default {
  props: {
    type: {
      type: String,
      required: true,
      validator: (type) => Object.keys(DOWNLOAD_TYPES()).includes(type),
    },
    label: {
      type: String,
    },
    tooltip: {
      type: String,
    },
    resource: {
      type: Object || File,
      required: true,
    },
    params: {
      type: Object,
    },
    iconBefore: {
      type: Boolean,
    },
    variant: {
      type: String,
    },
    confirm: {
      type: Function,
      required: false,
    },
    trackOverride: {
      type: Function,
      required: false,
    },
  },

  data() {
    return {
      progress: null,
      downloadStart: null,
    };
  },

  computed: {
    isDisabled() {
      const config = DOWNLOAD_TYPES(this.$t)[this.type];
      return config.isDisabled && config.isDisabled(this.resource);
    },
  },

  methods: {
    onClick() {
      if (this.confirm) {
        this.confirm(() => this.download());
        return;
      }

      return this.download();
    },
    onDownloadAllAsync(url) {
      this.$api
        .post(url)
        .then(() => {
          this.$toast(this.$t('files:downloadRequestSubmitted'));
        })
        .catch((error) => {
          console.error('Download request failed:', error);
          this.$toast.error(this.$t('files:downloadRequestFailed'));
        });
    },
    download() {
      const config = DOWNLOAD_TYPES(this.$t)[this.type];

      if (!config) {
        throw Error(`Unknown download type ${this.type}`);
      }

      if (config.override) {
        return this.onDownloadAllAsync(config.path(this.resource));
      }

      this.downloadStart = Date.now();
      return this.$api
        .request({
          url: config.path(this.resource),
          params: this.params,
          method: 'GET',
          responseType: 'blob',
          ...config.config,
          onDownloadProgress: (progressEvent) => {
            const totalLength = progressEvent.lengthComputable
              ? progressEvent.total
              : progressEvent.target.getResponseHeader('content-length') ||
                progressEvent.target.getResponseHeader(
                  'x-decompressed-content-length'
                );
            const msSinceStart = Date.now() - this.downloadStart;
            if (totalLength !== null && msSinceStart > 500) {
              this.progress = Math.round(
                (progressEvent.loaded * 100) / totalLength
              );
            }
          },
        })
        .then((resp) => {
          // inline; filename*=UTF-8''FILENAME.EXT
          const filename = resp.headers['content-disposition']
            ?.split('filename*=')[1]
            ?.replace(/"/g, '')
            .replace(`UTF-8''`, '');
          const decodedName = decodeURIComponent(filename);
          this.saveBlob(resp.data, config.fileName(this.resource, decodedName));
        })
        .catch((err) => {
          console.error(err);
          this.$toast.error(this.$t('files:errorDownloadingFile'));
        })
        .finally(() => {
          this.$emit('done');
          this.downloadStart = null;
          this.progress = null;
        });
    },

    saveBlob(blob, filename) {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      link.remove();
    },
  },
};
</script>

<style></style>
