<template>
  <BaseCard
    variant="transparent"
    border
    style="min-width: 0"
    :onClick="clickable ? button?.onClick : undefined"
    :class="{
      [$style.done]: todo.isDone,
      [$style.open]: !todo.isDone,
      [$style.wrapper]: true,
      [$style.button]: clickable,
    }"
  >
    <template v-if="onChecked">
      <div class="flex-hor-between align-start">
        <label class="text-nowrap flexAlign vanilla">
          <input
            type="checkbox"
            :checked="todo.isDone"
            @change="onChecked"
            :data-cy="`todo-${todo.key}`"
          />
          <strong>{{ todoT('title') }}</strong>
        </label>
      </div>
      <p v-if="!todo.isDone && description">{{ description }}</p>
    </template>

    <template v-else>
      <div class="flexAlign">
        <span :class="$style.bull" v-html="icon" />
        <template v-if="activeSessionType === 'Admin'">
          <div
            v-for="role of todo.roles.filter((role) => role !== 'Admin')"
            :key="role"
            :class="[$style.label, 'label']"
          >
            {{ role }}
          </div></template
        >
        <strong>{{ todoT('title') }}</strong>
        <Icon v-if="button?.icon" :icon="button.icon" />
        <strong v-if="todo.amount">{{
          $format.currency(todo.amount, todo.currency)
        }}</strong>
      </div>
      <div v-if="!todo.isDone">
        <p v-if="description">{{ description }}</p>
      </div>
    </template>
  </BaseCard>
</template>
<script setup lang="ts">
import BaseCard from '@/components/common/BaseCard.vue';
import { useModal, useRouter, useTranslation } from '@/composables/plugins';
import { DerivedTodoKey, Todo } from '@cutr/constants/todos/todo-controller';
import { computed } from 'vue';
import { CadFile, Order, User } from '@cutr/constants/order';
import { useActiveUserStore } from '@/modules/store';

type Props = {
  todo: Todo;
  order?: Order;
  files?: CadFile[];
  onChecked?: (event: Event) => void;
};

const props = defineProps<Props>();

const router = useRouter();
const modal = useModal();
function handleStatusEdit(order: unknown, action: unknown) {
  modal.open('maker-status-change', {
    action,
    order,
  });
}
const { activeSessionType } = useActiveUserStore() as {
  activeSessionType: User['type'];
};

const { t, i18next } = useTranslation();
const todoT = i18next.getFixedT(null, 'order-todo', `todos.${props.todo.key}`);

const icon = computed(() => (props.todo.isDone ? '&checkmark;' : '&#9679;'));

const description = computed(() => {
  const key = `order-todo:todos.${props.todo.key}.description`;
  return i18next.exists(key) && t(key);
});

const button = computed(() => actions[props.todo.key as DerivedTodoKey]);
const clickable = computed(
  () => !props.onChecked && !props.todo.isDone && button
);

const getOrderId = (order?: Order) => order?.id || '';
const getCustomerId = (order?: Order) => order?.customerId || '';

const goToOrderTab = (tab: string, query: Record<string, string> = {}) => {
  router.push({
    name: 'order:' + tab,
    params: { id: getOrderId(props.order), tab },
    query: { ...router.currentRoute.query, ...query },
  });
};

const actions: Record<
  DerivedTodoKey,
  { icon: string; onClick: () => void | Promise<void> } | null
> = {
  'create-quote': {
    icon: 'arrow_right_alt',
    onClick: () => {
      goToOrderTab('pricing');
    },
  },
  'update-quote': {
    icon: 'arrow_right_alt',
    onClick: () => {
      goToOrderTab('pricing');
    },
  },
  'dispatch-offer': {
    icon: 'arrow_right_alt',
    onClick: () => {
      goToOrderTab('pricing');
    },
  },
  'ready-for-manufacturing': null,
  'view-price-estimate': null,
  'checkout-order': null,
  'accept-quote': {
    icon: 'arrow_right_alt',
    onClick: () => {
      goToOrderTab('quotes');
    },
  },
  'review-estimate': {
    icon: 'arrow_right_alt',
    onClick: () => {
      goToOrderTab('quotes');
    },
  },
  'request-new-quote': {
    icon: 'arrow_right_alt',
    onClick: () => {
      goToOrderTab('quotes');
    },
  },
  'customer-invoice-reference': {
    icon: 'arrow_right_alt',
    onClick: () => {
      goToOrderTab('billing');
    },
  },
  'maker-invoice-reference': {
    icon: 'arrow_right_alt',
    onClick: () => {
      goToOrderTab('billing');
    },
  },
  'complete-account-details': {
    icon: 'arrow_right_alt',
    onClick: () => {
      if (activeSessionType === 'Admin') {
        router.push({
          name: 'users:settings',
          params: { userId: getCustomerId(props.order) },
        });
        return;
      }
      router.push({
        path: '/settings',
      });
    },
  },
  'set-order-name': null,
  'complete-billing-address': {
    icon: 'arrow_right_alt',
    onClick: () => {
      if (activeSessionType === 'Admin') {
        router.push({
          name: 'users:settings:addresses',
          params: { userId: getCustomerId(props.order) },
        });
        return;
      }
      router.push({
        path: '/account/addresses',
      });
    },
  },
  'complete-delivery-address': {
    icon: 'arrow_right_alt',
    onClick: () => {
      if (activeSessionType === 'Admin') {
        router.push({
          name: 'users:settings:addresses',
          params: { userId: getCustomerId(props.order) },
        });
        return;
      }
      router.push({
        path: '/account/addresses',
      });
    },
  },
  'complete-delivery-address-details': {
    icon: 'arrow_right_alt',
    onClick: () => {
      if (activeSessionType === 'Admin') {
        router.push({
          name: 'users:settings:addresses',
          params: { userId: getCustomerId(props.order) },
        });
        return;
      }
      goToOrderTab('delivery');
    },
  },
  'accept-offer': {
    icon: 'arrow_right_alt',
    onClick: () => {
      goToOrderTab('quotes');
    },
  },
  'material-delivery-date': {
    icon: 'arrow_right_alt',
    onClick: () => {
      goToOrderTab('delivery');
    },
  },
  'planned-completion-date': {
    icon: 'arrow_right_alt',
    onClick: () => {
      goToOrderTab('delivery');
    },
  },
  'delivery-schedule': {
    icon: 'arrow_right_alt',
    onClick: () => {
      goToOrderTab('delivery');
    },
  },
  'mark-ready': {
    icon: 'check_circle',
    onClick: () =>
      handleStatusEdit(props.order, {
        icon: 'check_circle',
        status: 'project_complete',
        label: t('workspace:menuButtons.markReady'),
      }),
  },
  'mark-delivered': {
    icon: 'local_shipping',
    onClick: () =>
      handleStatusEdit(props.order, {
        icon: 'local_shipping',
        status: 'project_delivered',
        label: t('workspace:menuButtons.markDelivered'),
      }),
  },
  'quote-within-budget': null,
  'apply-rush-fee': null,
  'apply-sanding': null,
  'nest-material': null,
  'apply-finish': null,
  'apply-edgebanding': null,
  'quote-design-work': null,
  'files-approval': null,
  'files-review': {
    icon: 'arrow_right_alt',
    onClick: () => {
      const file = props.files?.find((file) =>
        file.revisionReviews?.find(
          (review) =>
            review.status === 'pending-review' &&
            review.assigneeType === activeSessionType
        )
      );
      goToOrderTab('files', file ? { fileId: file.id } : {});
    },
  },
  'onhold-order': {
    icon: 'flag',
    onClick: () => goToOrderTab('messages'),
  },
};
</script>
<style module>
.button {
  &:hover {
    background-color: var(--gray-3);
  }
}

.wrapper p {
  margin: 0.5rem 0;
}

.wrapper input[type='checkbox'] {
  accent-color: currentcolor;
  width: auto;
}

.done {
  background-color: var(--green-1);
}

.bull {
  color: var(--red-6);
  font-size: 1rem;
}

.done .bull {
  color: inherit;
  font-size: 1.2em;
  font-weight: 600;
  position: relative;
  top: 2px;
}

.label {
  margin-right: 0 !important;
}
</style>
