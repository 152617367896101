<template>
  <div>
    <h3>{{ $t('landing-page:project.cabinet-configurator.description') }}</h3>

    <div class="bufferTop-lg" :class="$style.imagesBlock">
      <div class="flex-ver-center pad">
        <img
          class="cad-file-thumb buffer-bottom-sm"
          :class="$style.image"
          :src="'/img/wardrobe.png'"
        />
        <p>
          {{ $t('landing-page:project.cabinet-configurator.images.wardrobe') }}
        </p>
      </div>

      <div class="flex-ver-center pad">
        <img
          class="cad-file-thumb buffer-bottom-sm"
          :class="$style.image"
          :src="'/img/hallway.png'"
        />
        <p>
          {{ $t('landing-page:project.cabinet-configurator.images.hallway') }}
        </p>
      </div>

      <div class="flex-ver-center pad">
        <img
          class="cad-file-thumb buffer-bottom-sm"
          :class="$style.image"
          :src="'/img/shelves.png'"
        />
        <p>
          {{ $t('landing-page:project.cabinet-configurator.images.shelves') }}
        </p>
      </div>
    </div>

    <Button
      class="signal pill center units-2 buffer-lg big"
      style="margin-bottom: 3em"
      :on-click="onClick"
    >
      {{ $t('landing-page:project.cabinet-configurator.button') }}
    </Button>
  </div>
</template>

<script lang="ts" setup>
import { useRouter, useToast } from '@/composables/plugins';
import { useDraftOrder } from './composables';
import api from '@/helpers/api';
import { t } from 'i18next';
const { order, variantId, beforeCreate } = useDraftOrder();

const router = useRouter();
const toast = useToast();

async function onClick() {
  await beforeCreate();
  const cabinetFile = await api
    .post(`/v2/order-files/cabinet`, {
      orderVariantId: variantId.value,
    })
    .then(({ data }) => data.data)
    .catch(() => {
      toast.error(t('landing-page:errors.orderCreation'));
    });

  await router.push({
    name: `order:cabinator`,
    params: { id: order.value?.id || '' },
    query: {
      variantId: variantId.value,
      fileId: cabinetFile.id,
    },
  });
}
</script>

<style module scoped>
.imagesBlock {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;

  & .image {
    height: 100%;
    min-width: 8rem;
    width: auto;
  }

  & p {
    font-weight: 500;
    margin-top: 0.5rem;
    text-align: center;
    white-space: nowrap;
  }
}
</style>
