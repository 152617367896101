export function assert(condition: unknown, msg?: string): asserts condition {
  if (!condition) {
    throw new AssertionError(msg || 'Assertion failed');
  }
}

export function assertType<T>(
  value: unknown,
  type: string,
  msg?: string
): asserts value is T {
  if (typeof value !== type) {
    throw new AssertionError(msg || `Expected type ${type}`);
  }
}

class AssertionError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'AssertionError';
  }
}

export function assertNever(x: never): never {
  throw new Error('Unexpected object: ' + x);
}

type Truthy<T> = T extends false | '' | 0 | null | undefined ? never : T; // from lodash
export function notEmpty<T>(value: T): value is Truthy<T> {
  return Boolean(value);
}

export const uniqBy = <T>(arr: T[], fn: ((t: T) => unknown) | keyof T) => [
  ...new Map(
    arr.reverse().map((x) => [typeof fn === 'function' ? fn(x) : x[fn], x])
  ).values(),
];

export const isEmpty = (obj: unknown) =>
  // @ts-expect-error includes is a bitch
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;

// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_isplainobject
export function isPlainObject(value: unknown) {
  if (typeof value !== 'object' || value === null) return false;

  if (Object.prototype.toString.call(value) !== '[object Object]') return false;

  const proto = Object.getPrototypeOf(value);
  if (proto === null) return true;

  const Ctor =
    Object.prototype.hasOwnProperty.call(proto, 'constructor') &&
    proto.constructor;
  return (
    typeof Ctor === 'function' &&
    Ctor instanceof Ctor &&
    Function.prototype.call(Ctor) === Function.prototype.call(value)
  );
}

// type aware Array.includes
export function includes<T extends U, U>(
  elements: ReadonlyArray<T>,
  element: U
): element is T {
  return elements.includes(element as T);
}

export function isEmail(value: string) {
  const isEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return isEmail.test(value);
}

export function assertHasProp(object: Record<string, unknown>, prop: string) {
  assertType<string>(prop, 'string');
  assertType<object>(object, 'object');
  assert(prop in object, `Expected object to have property ${prop}`);
}
