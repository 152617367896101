import Debug from 'debug';
const debug = Debug('tracker:event-handler');
import Vue from 'vue';
import { omit, pick } from '../helpers';

// vitest doesnt have window context, and dont want to add more requirements to it
if (typeof window !== 'undefined') {
  window?.analytics?.addSourceMiddleware(({ payload, next }) => {
    const event = payload.obj;
    if (event.type === 'track') {
      if (event.properties?.orderId) {
        event.groups = { orderId: event.properties.orderId };
      }
    }
    next(payload);
  });
}

export const TRACKING_HANDLERS = {
  identify: (user, segment, abTestFlags) => {
    if (!user.email) return;
    segment.identify(
      user.id,
      { ...toSegmentUser(user), ...abTestFlags },
      {
        integrations: {
          Intercom: {
            user_hash: user.intercomUserHash,
            language_override: user.locale,
          },
        },
      }
    );
  },

  page: ({ title }, segment) =>
    segment.page(title, {
      maintenanceMode: Vue.$feature.isMaintenanceModeEnabled(),
    }),

  'page:order': ({ title, order }, segment) => {
    segment.page(title, {
      ...toSegmentOrder(order),
      maintenanceMode: Vue.$feature.isMaintenanceModeEnabled(),
    });
  },

  'invite-sent': (user, segment) => {
    segment.track('Invite Sent', {
      inviteCode: user.signupCode,
      ...toSegmentUser(user),
    });
  },

  signup: (user, segment) => {
    segment.track('Signed up', toSegmentUser(user));
  },

  'signup:invited': ({ code, user }, segment) => {
    segment.track('Signed up', {
      inviteCode: code,
      ...toSegmentUser(user),
    });
  },

  signin: (user, segment) => {
    segment.track('Signed in', toSegmentUser(user));
  },

  signout: (user, segment) => {
    if (!user) return;
    segment.track('Signed out', toSegmentUser(user));
    segment.reset();
  },

  'order:cadfile-upload': ({ filetype, source, order }, segment) => {
    const filetypeUnique = Array.from(new Set(filetype?.split(', ')))
      .sort()
      .join(', ');
    segment.track('Order CADFile Uploaded', {
      filetype: filetypeUnique,
      source,
      ...toSegmentOrder(order),
    });
  },

  'order:cadfile-uploading': ({ source, filesCount, order }, segment) => {
    segment.track('Uploading Order CADFiles', {
      filesCount,
      source,
      ...toSegmentOrder(order),
    });
  },

  'order:cadfile-update': ({ issues, order }, segment) => {
    segment.track('Order CADFile Update', {
      issues: issues,
      order: toSegmentOrder(order),
    });
  },

  'order:parts-loaded': (order, segment) => {
    segment.track('Parts Loaded', toSegmentOrder(order));
  },

  'order:cutlist-create': (order, segment) => {
    segment.track('Order Cutlist Created', toSegmentOrder(order));
  },

  'order:support-file-upload': (order, segment) => {
    segment.track('Order Supporting File Uploaded', toSegmentOrder(order));
  },

  'order:support-file-upload-by-cta': (order, segment) => {
    segment.track(
      'Order Supporting File Uploaded by CTA',
      toSegmentOrder(order)
    );
  },

  'order:options-change': ({ changedField, order }, segment) => {
    segment.track('Order Options Change', {
      changedField,
      ...toSegmentOrder(order),
    });
  },

  'order:tab-view': ({ tab, order }, segment) => {
    segment.track('Order Tab Viewed', {
      tab,
      ...toSegmentOrder(order),
    });
  },

  'order:submit': (order, segment) => {
    segment.track('Order Submitted', toSegmentOrder(order));
  },

  'order:intake': ({ order, isDraft = false }, segment) => {
    segment.track('Intake Submit', {
      isDraft,
      ...toSegmentOrder(order),
    });
  },
  'order:intake-save-and-close': ({ order, isDraft = false }, segment) => {
    segment.track('Save and Close order', {
      isDraft,
      ...toSegmentOrder(order),
    });
  },
  'order:intake-direction': (
    { order, orderId, direction, navigatedTo },
    segment
  ) => {
    segment.track(`intake-${direction}`, {
      navigatedTo,
      ...toSegmentOrder(order),
    });
  },
  'order:error': ({ event, order, err }, segment) => {
    segment.track('Order Error', {
      errorEvent: event,
      error: `${err}`,
      ...toSegmentOrder(order),
    });
  },

  'order:material-selection-step': (
    { event, step, isEditing, material },
    segment
  ) => {
    if (!['Started', 'Complete'].includes(event)) {
      throw new TypeError(`Invalid event ${event}`);
    }
    segment.track(`Material Selection Step ${event}`, {
      step,
      isEditing,
      material: toSegmentMaterialRequest(material),
    });
  },
  'order:material-search': (
    { searchTerms, rawInput, currentStep, category },
    segment
  ) => {
    segment.track(`Material Search`, {
      searchTerms,
      rawInput,
      currentStep,
      category,
    });
  },
  'order:message-sent': (data, segment) => {
    segment.track(`Message Sent`, data);
  },

  'order:estimate-requested': (data, segment) => {
    segment.track(`Estimate Requested`, data);
  },

  'order:estimate-calculated': (data, segment) => {
    segment.track(`Estimate Calculated`, data);
  },

  'click:help-link': ({ url, tooltip }, segment) => {
    segment.track('Clicked Help Link', { url, tooltip });
  },

  'offer:viewed': ({ offer }, segment) => {
    segment.track('Offer Viewed', toSegmentQuote(offer));
  },
  'offer:adjustment-response': (data, segment) => {
    segment.track('Offer Adjustment Response From Platform', data);
  },
  'quote:viewed': ({ quote }, segment) => {
    segment.track('Quote Viewed', toSegmentQuote(quote));
  },
  'quote:accepted': ({ quote }, segment) => {
    segment.track('Quote Accepted', toSegmentQuote(quote));
  },
  'quote:downloaded': ({ quote }, segment) => {
    segment.track('Quote Downloaded', toSegmentQuote(quote));
  },
  'quote:adjustment-response': (data, segment) => {
    segment.track('Quote Adjustment Response From Platform', data);
  },

  'quote-estimate:viewed': ({ quote }, segment) => {
    segment.track('Quote Estimate Viewed', toSegmentQuoteEstimate(quote));
  },
  'quote-estimate:accepted': ({ quote }, segment) => {
    segment.track('Quote Estimate Accepted', toSegmentQuoteEstimate(quote));
  },
  'quote-estimate:feedback': ({ quote }, segment) => {
    segment.track('Quote Estimate Feedback', toSegmentQuoteEstimate(quote));
  },
  'quote-estimate:saved': ({ quote }, segment) => {
    segment.track('Quote Estimate Saved', toSegmentQuoteEstimate(quote));
  },
  'quote-estimate:update-requested': ({ quote }, segment) => {
    segment.track(
      'Quote Estimate Update Requested',
      toSegmentQuoteEstimate(quote)
    );
  },

  'feedback:viewed': (data, segment) => {
    segment.track('Manufacturing Feedback Viewed', data);
  },
  'feedback:viewed-individual-issue': (data, segment) => {
    segment.track('Manufacturing Issue Viewed', data);
  },
  'feedback:viewed-file-issues': (data, segment) => {
    segment.track('All Manufacturing Issues Viewed for File', data);
  },

  'invoice:viewed': ({ invoice }, segment) => {
    segment.track('Invoice Viewed', toSegmentInvoice(invoice));
  },
  'invoice:downloaded': ({ invoice }, segment) => {
    segment.track('Invoice Downloaded', toSegmentInvoice(invoice));
  },

  'invoice:finalized': ({ invoice }, segment) => {
    segment.track('Invoice Manually Finalized', toSegmentInvoice(invoice));
  },

  'card-click': (data, segment) => {
    segment.track('Card Clicked', data);
  },

  'tabnav-click': (data, segment) => {
    segment.track('Tab Nav Clicked', data);
  },

  'modal:open:sign-in': (data, segment) => {
    segment.track('Sign-in Modal Opened', data);
  },

  'modal:close:sign-in': (data, segment) => {
    segment.track('Sign-in Modal Closed', data);
  },

  'modal:open': (data, segment) => {
    segment.track('Modal Opened', data);
  },

  'modal:close': (data, segment) => {
    segment.track('Modal Closed', data);
  },

  'apply-materials:nesting-material-group': (data, segment) => {
    segment.track(
      'Applied Different Sheet Dimensions From Nesting Results',
      data
    );
  },

  'apply-materials:material-to-selection': (data, segment) => {
    segment.track('Applied One Material To Selected Parts', data);
  },

  'apply-properties-to-parts': (data, segment) => {
    segment.track('Applied Properties To Parts', data);
  },

  'apply-materials:group-to-selection': (data, segment) => {
    segment.track('Applied Material Group To Selected Parts', data);
  },
  'set-assignment-material': (data, segment) => {
    segment.track('Select Material To Assign', data);
  },
  'assign-material-to-parts': (data, segment) => {
    segment.track('Assign Material To Parts', data);
  },
  'clear-material-from-parts': (data, segment) => {
    segment.track('Clear Material From Parts', data);
  },
  'change-part-selection': (data, segment) => {
    segment.track('Change Part Selection', data);
  },

  increment: (data, segment) => {
    const { title, ...payload } = data;
    segment.track(title, payload);
  },

  'page:launchpad:video-button': (data, segment) => {
    segment.track('Landing Page Video Button Clicked', data);
  },

  'page:launchpad:download-sample-file-button': (data, segment) => {
    segment.track('Landing Page Sample File Clicked', data);
  },
  'page:launchpad:design-files-quote': (data, segment) => {
    segment.track('Marketplace Quote Card Clicked', data);
  },
  'page:launchpad:cutlist-quote': (data, segment) => {
    segment.track('Cutlist Quote Card Clicked', data);
  },
  'page:launchpad:cabined-configurator-quote': (data, segment) => {
    segment.track('Cabinet Configurator Quote Card Clicked', data);
  },
  'page:launchpad:email-quote': (data, segment) => {
    segment.track('Email Quote Card Clicked', data);
  },
  'page:launchpad:upload-file': (data, segment) => {
    segment.track('Upload File Area Clicked', data);
  },
  'page:launchpad:upload-started': ({ order }, segment) => {
    segment.track('Upload Started', {
      ...toSegmentOrder(order),
    });
  },
  'page:launchpad:upload-finished': ({ order }, segment) => {
    segment.track('Upload Finished', {
      ...toSegmentOrder(order),
    });
  },
  'page:launchpad:privacy-policy': (data, segment) => {
    segment.track('Privacy Policy Clicked', data);
  },

  'consent-manager:segment-bad-cookie': (data, segment) => {
    segment.track('Faulty consent manager cookie deleted', data);
  },

  'page:file-options:quantity-clicked': (data, segment) => {
    segment.track('Clicked Quantity', data);
  },
  'page:file-options:assembly-clicked': (data, segment) => {
    segment.track('Clicked Assembly', data);
  },

  'page:order:user-went-back': (data, segment) => {
    segment.track('User went back', data);
  },

  'page:order:proposal-navigation-email': (data, segment) => {
    segment.track('User navigated to order from adjustment email', data);
  },

  'page:delivery:new-date-selected': (data, segment) => {
    segment.track('Customer Selected A New Date', data);
  },
  'page:delivery:budget': (data, segment) => {
    segment.track('Customer Specified A Budget', data);
  },
  'page:delivery:postal-code': (data, segment) => {
    segment.track('Customer Entered A Postal Code', data);
  },
  'page:delivery:show-full-address-form': (data, segment) => {
    segment.track('Customer Showed Full Address Form', data);
  },

  'page:review:view-all-files': (data, segment) => {
    segment.track('Viewed All Files Clicked', data);
  },
  'page:review:edit-file-options': (data, segment) => {
    segment.track('Edit File Options Clicked', data);
  },
  'page:review:updated-order-notes': (data, segment) => {
    segment.track('Updated Order Notes', data);
  },

  'order:updated-name': (data, segment) => {
    segment.track('Updated Order Name', data);
  },
  'order:tab-changed': (data, segment) => {
    segment.track('Order Intake Flow Tab Changed', data);
  },

  'viewer:fit-to-view': (data, segment) => {
    segment.track('Viewer Fit To View Clicked', data);
  },
  'viewer:extension': (data, segment) => {
    segment.track('Viewer Extension Loaded', data);
  },

  'intercom:shown': (data, segment) => {
    segment.track('Intercom Shown', data);
  },
  'intercom:button:click': (data, segment) => {
    segment.track('Intercom Button Clicked', data);
  },

  'checkout:data-collection': ({ from, order, section = null }, segment) => {
    segment.track('Collecting data for dispatch', {
      ...toCheckoutEvent(order),
      from,
      section,
    });
  },
};

export function toSegmentUser(user) {
  const [firstName, ...lastNameParts] = (user.name || '').split(' ');
  const isSuperUser = Boolean(user.superUserId);
  return {
    userId: user.id,
    firstName,
    lastName: lastNameParts.join(' '),
    company: user.companName,
    phone: user.phone,
    email: user.email,
    // eslint-disable-next-line no-restricted-properties
    type: user.type,
    sessionType: user.sessionType,
    locale: user.locale,
    createdAt: user.signedUpAt,
    isInternal: isSuperUser || isInternalEmail(user.email),
    isSuperUser,
  };
}

const INTERNAL_DOMAINS = ['cutr.ai', 'cutr.com', 'raradata.com'];
function isInternalEmail(email) {
  return INTERNAL_DOMAINS.some((domain) => (email || '').endsWith(domain));
}

export function toSegmentOrder(order) {
  const data = pick(order, [
    'shortId',
    'createdAt',
    'orderstatus',
    'quantity',
    'name',
    'additionalInfo',
    'assemblyRequested',
    'leftOverMaterialManagement',
    'delivery',
  ]);
  data.orderId = order.id;
  return data;
}

export function toSegmentQuote(quote) {
  const data = pick(quote, [
    'label',
    'status',
    'orderId',
    'shortOrderId',
    'quantity',
    'totalAmount',
  ]);
  data.quoteId = quote.id;
  return data;
}

export function toSegmentQuoteEstimate(quoteEstimate) {
  const data = pick(quoteEstimate, [
    'label',
    'status',
    'orderId',
    'orderVariantId',
    'minTotalAmount',
    'maxTotalAmount',
  ]);
  data.quoteId = quoteEstimate.id;
  return data;
}
export function toSegmentInvoice(invoice) {
  const data = pick(invoice, [
    'label',
    'status',
    'orderId',
    'shortOrderId',
    'quantity',
    'totalAmount',
  ]);
  data.invoiceId = invoice.id;
  return data;
}

export function toSegmentMaterialRequest(materialRequest) {
  return omit(materialRequest, ['userId', 'archivedAt', 'updatedAt']);
}

export const CHECKOUT_FIELDS_TRACKING = [
  ['name', 'order-name'],
  ['requestedDeliveryDate', 'delivery-date'],
  ['customerFulfilmentAddressId', 'delivery-address'],
  ['customerBillingAddressId', 'billing-address'],
  ['customer.customerOrganisation.entityType', 'business-setting'],
  ['customer.business', 'business-details'],
  ['customer.customerOrganisation.invoiceEmail', 'invoice-email'],
];

const get = (obj, path) =>
  path
    .split('.')
    .filter(Boolean)
    .reduce((res, key) => (res != null ? res[key] : res), obj);

export function toCheckoutEvent(order) {
  const fieldsMissing = [];
  const fieldsPopulated = [];

  CHECKOUT_FIELDS_TRACKING.forEach(([key, value]) => {
    if (get(order, key)) {
      fieldsPopulated.push(value);
    } else {
      fieldsMissing.push(value);
    }
  });

  if (order.reference || order.referenceIgnore === true) {
    fieldsPopulated.push('invoice-reference');
  } else {
    fieldsMissing.push('invoice-reference');
  }

  return {
    orderId: order.id,
    orderStatus: order.orderstatus,
    fieldsMissing: fieldsMissing.sort(),
    fieldsPopulated: fieldsPopulated.sort(),
  };
}

export function track(event, data) {
  const trackingEnabled = Vue.$feature.isEnabled('segment-tracking');
  const abTestFlags = Vue.$feature.getAbTestFlags();

  const handler = TRACKING_HANDLERS[event];
  if (!handler) {
    debug(`Error: Unknown event handler ${event}`, { event, data });
    return;
  }
  try {
    const e = event.split(':').slice(0, 2);
    const page = e[0] === 'page' ? e[1] : undefined;

    if (trackingEnabled) {
      handler({ page, ...data }, window.analytics, abTestFlags);
    }
    debug(`event handled: ${event}`, { data, event, trackingEnabled });
  } catch (error) {
    debug(`Error: Failed to handle event ${event}`, {
      data,
      error,
      trackingEnabled,
    });
  }
}
