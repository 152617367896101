<template>
  <div class="bg-pattern" :class="$style.main">
    <section :class="$style.white">
      <header>
        <h2>{{ $t('landing-page:title') }}</h2>
      </header>
      <div>
        <a
          v-if="feature.isEnabled('cabinator')"
          :class="[
            $style.bigButton,
            quoteType === 'cabinet-configurator' && $style.active,
          ]"
          class="flexAlign opposites buffer-bottom"
          @click="() => changeQuoteType('cabinet-configurator')"
          :href="isMobile ? '#content' : undefined"
        >
          <div>
            <h3>{{ $t('landing-page:project.cabinet-configurator.title') }}</h3>
            <div class="flexAlign buffer-top-sm">
              <div class="tag">3D</div>
              <div class="tag">2D</div>
            </div>
            <p class="text-subtle buffer-top-sm">
              {{ $t('landing-page:project.cabinet-configurator.description') }}
            </p>
          </div>
          <div :class="$style.iconArrow">{{ 'chevron_right' }}</div>
        </a>

        <a
          :class="[
            $style.bigButton,
            quoteType === 'design-files' && $style.active,
          ]"
          class="flexAlign opposites buffer-bottom"
          @click="() => changeQuoteType('design-files')"
          :href="isMobile ? '#content' : undefined"
          data-cy="launchpad-design-files-button"
        >
          <div>
            <h3>{{ $t('landing-page:project.design-files.title') }}</h3>
            <div class="flexAlign buffer-top-sm">
              <div class="tag">3D</div>
              <div class="tag">2D</div>
              <div class="tag">PDF</div>
              <div class="tag">{{ $t('landing-page:tags.drawings') }}</div>
            </div>
            <p class="text-subtle buffer-top-sm">
              {{ $t('landing-page:project.design-files.description') }}
            </p>
          </div>
          <div :class="$style.iconArrow">{{ 'chevron_right' }}</div>
        </a>

        <a
          :class="[
            $style.bigButton,
            quoteType === 'cutlist-project' && $style.active,
          ]"
          class="flexAlign opposites"
          @click="() => changeQuoteType('cutlist-project')"
          data-cy="launchpad-create-cutlist"
          :href="isMobile ? '#content' : undefined"
        >
          <div>
            <h3>{{ $t('landing-page:project.cutlist.title') }}</h3>
            <div class="flexAlign buffer-top-sm">
              <div class="tag">{{ $t('landing-page:tags.cutlist') }}</div>
              <div class="tag">{{ 'CSV' }}</div>
            </div>
            <p class="text-subtle buffer-top-sm">
              <i18next
                :translation="$t('landing-page:project.cutlist.description')"
              >
                <template #descriptionBold>
                  <strong>{{
                    $t('landing-page:project.cutlist.descriptionBold')
                  }}</strong>
                </template>
              </i18next>
            </p>
          </div>
          <div :class="$style.iconArrow">{{ 'chevron_right' }}</div>
        </a>

        <hr />

        <a
          :class="$style.bigButton"
          class="flexAlign opposites"
          style="background: var(--gray-1); text-decoration: none"
          href="mailto:quote@cutr.ai?subject=Request a quote"
          @click="() => $track('page:launchpad:email-quote', {})"
        >
          <div>
            <h3>{{ $t('landing-page:project.email.title') }}</h3>

            <p class="text-subtle buffer-top-sm">
              <i18next
                :translation="$t('landing-page:project.email.description')"
              >
                <template #email>
                  <a href="mailto:quote@cutr.ai?subject=Request a quote">{{
                    'quote@cutr.ai'
                  }}</a>
                </template>
              </i18next>
            </p>
          </div>
          <div :class="$style.iconBtn">
            <span data-i="email">{{
              $t('landing-page:project.email.button')
            }}</span>
          </div>
        </a>
      </div>

      <LaunchpadLogins class="buffer-top" />
    </section>

    <section id="content">
      <header style="visibility: hidden" v-if="!isMobile">
        <h2>{{ $t('landing-page:title') }}</h2>
      </header>

      <TabUpload v-if="quoteType === 'design-files'" />
      <TabCutlist v-if="quoteType === 'cutlist-project'" />
      <TabCabinetConfigurator v-if="quoteType === 'cabinet-configurator'" />
    </section>
    <Footer />
  </div>
</template>

<script lang="ts" setup>
import { useFeatureFlags, useRoute, useTrack } from '@/composables/plugins';
import { onUnmounted, ref } from 'vue';
import { Route } from 'vue-router';
import Footer from './LaunchpadFooter.vue';
import LaunchpadLogins from './LaunchpadLogins.vue';
import TabCabinetConfigurator from './TabCabinetConfigurator.vue';
import TabCutlist from './TabCutlist.vue';
import TabUpload from './TabUpload.vue';

const feature = useFeatureFlags();

type QuoteType = 'design-files' | 'cutlist-project' | 'cabinet-configurator';

const mediaQuery = window.matchMedia('(min-width: 860px)');
const isMobile = ref(!mediaQuery.matches);
const handleReponsiveSize = (e: MediaQueryListEvent) => {
  isMobile.value = !e.matches;
};
mediaQuery.addEventListener('change', handleReponsiveSize);

onUnmounted(() =>
  mediaQuery.removeEventListener('change', handleReponsiveSize)
);

const track = useTrack();
const route: Route = useRoute();
const getQuoteType = () => {
  const quoteType = route.query.quoteType;

  if (
    quoteType === 'design-files' ||
    quoteType === 'cutlist-project' ||
    quoteType === 'cabinet-configurator'
  ) {
    return quoteType;
  }

  return feature.isEnabled('cabinator')
    ? 'cabinet-configurator'
    : 'design-files';
};

const changeQuoteType = (newType: QuoteType) => {
  quoteType.value = newType;
  if (newType === 'design-files') {
    track('page:launchpad:design-files-quote', {});
  }

  if (newType === 'cutlist-project') {
    track('page:launchpad:cutlist-quote', {});
  }

  if (newType === 'cabinet-configurator') {
    track('page:launchpad:cabined-configurator-quote', {});
  }
};

const quoteType = ref<QuoteType>(getQuoteType());
</script>
<style module>
.main {
  font-size: 1rem;
  display: grid;
  gap: 0;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr auto;
  min-height: calc(100vh - var(--navbarHeight));
}

.main footer {
  grid-column: 1 / -1;
  border-top: 1px solid var(--gray-5);
  align-self: flex-end;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 2rem;
}

.main section,
.main footer {
  padding: 2rem 3rem;
}

.main header {
  padding: 0 0 2rem;
}

.main h3 {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2rem;
}

.main a h3 {
  font-size: 1.25rem;
  line-height: 1.2;
}

.white {
  background-color: var(--gray-1);
}

.bigButton {
  --bkgColor: var(--gray-2);
  --statusColor: var(--gray-5);
  --iconSize: 2.5rem;

  padding: 1rem;
  border: 1px solid var(--statusColor);
  border-radius: 0.75rem;
  background: var(--bkgColor);
  cursor: pointer;
  text-decoration: none;

  &:hover,
  &.active {
    --bkgColor: var(--gray-1);
    --statusColor: var(--gold-6);

    & .iconArrow,
    & .iconBtn {
      color: var(--statusColor);
    }
  }

  &.active {
    box-shadow: 0px 3px 6px -4px hsla(0, 0%, 0%, 0.12),
      0px 6px 16px 0px hsla(0, 0%, 0%, 0.08),
      0px 9px 28px 8px hsla(0, 0%, 0%, 0.05);
  }
}

.bigButton p {
  margin-bottom: 0;
}

.iconArrow,
.iconBtn {
  line-height: 1;
  color: var(--gray-9);
  border: 1px solid var(--statusColor);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconArrow {
  height: var(--iconSize);
  width: var(--iconSize);
  font-family: 'Material Icons Outlined';
  font-size: 1.5rem;
}
.iconBtn {
  height: 2rem;
  border-radius: 1.25rem;
  padding: 0 1.25rem;
}

.main :global(.file-uploader label) {
  background-color: var(--gold-1);
}

@media screen and (max-width: 860px) {
  .main {
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: auto 1fr;
  }

  .main footer {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .iconArrow {
    display: none;
  }

  .iconBtn {
    margin-top: 1rem;
  }

  .bigButton.bigButton {
    display: block;
  }

  :global(.navbar.navbar) {
    position: relative;
  }

  :global(#main-view[data-route-name='home']) {
    --navbarHeight: 0;
  }
}
</style>
